import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectIsAuthenticated } from '../features/user/userSlice';
import {
  resetSignUp,
  selectNumberOfLicenses,
  selectStep,
  setStep,
} from '../features/signUp/signUpSlice';
import { store, useAppSelector } from '../stores/AppStore';

import MentorDetails from '../components/SignUp/MentorDetails';
import Payment from '../components/SignUp/Payment/Payment';
import SubscriptionConfirm from '../components/SignUp/SubscriptionConfirm';
import ThinkerDetails from '../components/SignUp/ThinkerDetails';
import TrackRecommendation from '../components/SignUp/TrackRecommendation';
import SignUpQuestionnaire from '../components/SignUp/Questionnaire';
import toast from 'react-hot-toast';
import About from './About';

const SignUp = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const step = useAppSelector(selectStep);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const [previousIndex, setPreviousIndex] = useState(1);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const skipAbout = searchParameters.get('skipAbout');

  const handleNextStep = () => {
    store.dispatch(setStep(step + 1));
  };

  const handlePreviousStep = () => {
    store.dispatch(setStep(step - 1));
  };

  useEffect(() => {
    setTimeout(() => {
      setPreviousIndex(step);
    }, 500);
  }, [step]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/login');
      return;
    }
    const firstStep = typeof skipAbout === 'string' ? 1 : 0;
    store.dispatch(resetSignUp(firstStep));
    setSearchParameters('');
  }, []);

  const renderStep = () => {
    const thinkerDetailSteps = numberOfLicenses * 2; // Each thinker detail step followed by a track recommendation step

    if (step === 0) {
      return <About onGetStarted={handleNextStep} />;
    }
    if (step === 1) {
      return (
        <Payment
          onPreviousStep={handlePreviousStep}
          onNextStep={() => {
            // TODO: conditional plural
            toast.success(
              `Successful payment! Now lets set up your Thinker's account.`,
            );
            handleNextStep();
          }}
        />
      );
    }
    if (step === 2) {
      return (
        <MentorDetails
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
        />
      );
    }
    if (step >= 3 && step < 3 + thinkerDetailSteps) {
      const thinkerSetupStep = step - 3;
      return thinkerSetupStep % 2 === 0 ? (
        <ThinkerDetails
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          licenseIndex={Math.floor(thinkerSetupStep / 2)}
        />
      ) : (
        <TrackRecommendation
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          licenseIndex={Math.floor(thinkerSetupStep / 2)}
        />
      );
    }
    if (step === 3 + thinkerDetailSteps) {
      return (
        <SignUpQuestionnaire
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
        />
      );
    }
    if (step === 4 + thinkerDetailSteps) {
      return <SubscriptionConfirm />;
    }
  };

  return (
    <div
      className={`
        ${
          step > 1 ? 'mt-8 md:mt-0' : ''
        } flex flex-col mx-auto w-full h-full md:w-[90%] items-center p-4
        ${
          previousIndex < step
            ? 'animate-slideInFromRight'
            : previousIndex > step
            ? 'animate-slideInFromLeft'
            : ''
        }
      `}
    >
      {renderStep()}
    </div>
  );
};

export default SignUp;

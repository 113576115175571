import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FromSchema } from 'json-schema-to-ts';

import { getPersistedState } from '../../services/LocalStorageService';
import { RootState } from '../../stores/AppStore';
import { getPrice } from '../../lib/utils';

const signUpStateSchema = {
  type: 'object',
  properties: {
    step: { type: 'number' },
    stepTitle: { type: 'string' },
    numberOfLicenses: { type: 'number' },
    thinkers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          firstName: { type: 'string' },
          lastName: { type: 'string' },
          email: { type: 'string' },
          trackId: { type: 'string' },
        },
        required: ['firstName', 'lastName', 'email', 'trackId'],
        additionalProperties: false,
      },
    },
    mentor: {
      type: 'object',
      properties: {
        firstName: { type: 'string' },
        lastName: { type: 'string' },
        email: { type: 'string' },
        phone: { type: 'string' },
        numberOfChildren: { type: 'string' },
        goal: { type: 'string' },
        preferredDevice: { type: 'string' },
        referral: { type: 'string' },
      },
      required: ['firstName', 'lastName', 'email', 'phone'],
      additionalProperties: false,
    },
    paymentIntentId: { type: 'string' },
    amounts: {
      type: 'object',
      properties: {
        amount: { type: 'number' },
        discount: { type: 'number' },
        tax: { type: 'number' },
        total: { type: 'number' },
      },
      additionalProperties: false,
    },
  },
  required: ['step', 'stepTitle', 'numberOfLicenses', 'thinkers', 'mentor'],
  additionalProperties: false,
} as const;

export type SignUpState = FromSchema<typeof signUpStateSchema>;

const initialState = getPersistedState<SignUpState>(
  'signUp',
  signUpStateSchema,
) ?? {
  step: 0,
  stepTitle: '',
  numberOfLicenses: 1,
  thinkers: [{ firstName: '', lastName: '', email: '', trackId: '' }],
  mentor: { firstName: '', lastName: '', email: '', phone: '' },
  amounts: {
    amount: 100 * getPrice(1),
  },
};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
      switch (action.payload) {
        case 0: {
          state.stepTitle = '';
          break;
        }
        case 1: {
          state.stepTitle = 'Online Checkout';
          break;
        }
        case 2: {
          state.stepTitle = 'Mentor Details';
          break;
        }
        case 3 + state.numberOfLicenses * 2: {
          state.stepTitle = 'Questionnaire';
          break;
        }
        case 4 + state.numberOfLicenses * 2: {
          state.stepTitle = '';
          break;
        }
        default: {
          if ((action.payload - 3) % 2 === 0) {
            state.stepTitle = `Student ${
              Math.floor((action.payload - 3) / 2) + 1
            } - Personal Details`;
          } else if ((action.payload - 3) % 2 === 1) {
            state.stepTitle = `Student ${
              Math.floor((action.payload - 3) / 2) + 1
            } - Learning Track`;
          }
        }
      }
    },
    setNumberOfLicenses: (state, action: PayloadAction<number>) => {
      console.log('setNumberOfLicences', action);
      state.numberOfLicenses = action.payload;
    },
    setThinkers: (state, action: PayloadAction<SignUpState['thinkers']>) => {
      state.thinkers = action.payload;
    },
    setThinkerTrack: (
      state,
      action: PayloadAction<{ index: number; trackId: string }>,
    ) => {
      state.thinkers[action.payload.index].trackId = action.payload.trackId;
    },
    setMentor: (state, action: PayloadAction<SignUpState['mentor']>) => {
      state.mentor = action.payload;
    },
    setPaymentIntentId: (state, action: PayloadAction<string>) => {
      state.paymentIntentId = action.payload;
    },
    setAmounts: (
      state,
      action: PayloadAction<{
        amount?: number;
        discount?: number;
        tax?: number;
        total?: number;
      }>,
    ) => {
      state.amounts = action.payload;
    },
    resetSignUp: (state, action: PayloadAction<number>) =>
      action.payload > 0
        ? { ...initialState, step: action.payload }
        : initialState,
  },
});

export const {
  setStep,
  setNumberOfLicenses,
  setThinkers,
  setThinkerTrack,
  setMentor,
  setPaymentIntentId,
  setAmounts,
  resetSignUp,
} = signUpSlice.actions;

export const selectStep = (state: RootState) => state.signUp.step;
export const selectStepTitle = (state: RootState) => state.signUp.stepTitle;
export const selectNumberOfLicenses = (state: RootState) =>
  state.signUp.numberOfLicenses;
export const selectThinkers = (state: RootState) => state.signUp.thinkers;
export const selectThinkerFirstNames = (state: RootState) =>
  state.signUp.thinkers.map((t) => t.firstName);
export const selectMentor: (state: RootState) => typeof state.signUp.mentor = (
  state: RootState,
) => state.signUp.mentor;
export const selectAmounts = (state: RootState) => state.signUp.amounts;
export const selectPaymentIntentId = (state: RootState) =>
  state.signUp.paymentIntentId;

export default signUpSlice.reducer;
